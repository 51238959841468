.loginbody {
  max-width: 400px;
  margin: 0 auto;
  width: 95%;
}

.striped-container {
  position: relative;
  margin-top: 24vh;
  min-height: 76vh;
  left: -30%;
  max-width: 300px;
  backface-visibility: hidden;
  background: black;
  background: repeating-linear-gradient(to right, white, white 10px, black 10px, black 20px);
}

.striped-container:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  left: 0;
  min-height: 12vh;
  width: 100%;
  transform: skewX(-45deg);
  transform-origin: 100% 100%;
  backface-visibility: hidden;
  background: black;
  background: repeating-linear-gradient(to right, white, white 10px, black 10px, black 20px);
}

.striped-container:after {
  content: "";
  display: block;
  position: absolute;
  bottom: calc(100% + 12vh);
  left: 0;
  min-height: 12vh;
  width: 100%;
  transform: skewX(45deg);
  transform-origin: 0 0;
  backface-visibility: hidden;
  background: black;
  background: repeating-linear-gradient(to right, white, white 10px, black 10px, black 20px);
}

.login-header {
  font-weight: normal;
  font-size: 8vh;
}

.login-form {
  padding: 2em;
  background: white;
  position: relative;
  left: 34%;
  width: 90vw;
  max-width: 450px;
}

.login-input,.login-label {
  display: block;
}

.login-input, .login-button {
  width: 100%;
  padding: 0.5em;
  font-size: 1.2em;
  box-sizing: border-box;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: solid 1px black;
  margin: 0.5em 0 1em;
  color: gray;
  font-family: Montserrat, sans-serif;
  transition: background 0.3s ease-in;
}

.login-input:hover {
  background: rgba(255, 255, 255, 0.2);
}

.login-button {
  background: #F68838;
  border: none;
  color: white;
  margin-top: 2em;
  position: relative;
  padding: 1em;
  font-family: "Montserrat", sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* For some Androids */
}

.login-button:hover,
.login-button:focus {
  background-color: #1b617d;
}

.login-button:active {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* .error {
  border-bottom: solid 2px gold;
}

.error-message {
  color: gold;
  position: absolute;
  margin-top: -0.5em;
  display: block;
} */

/* Css for ripples */
.ripple {
  overflow: hidden;
}

.ripple-effect {
  position: absolute;
  width: 10px;
  height: 300px;
  background: white;
}

@keyframes ripple-animation {
  from {
    -webkit-transform: scale(1);
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(100);
    opacity: 0;
  }
}