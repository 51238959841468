@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;700&family=Noto+Sans+JP:wght@100;200;400;500&family=Mukta:ital,wght@0,100;0,300;1,500;1,700&family=Zen+Kaku+Gothic+Antique:wght@300;400;500&display=swap');
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto
}

:root {
  --dark-color: #323A45;
  --color-fff: #FFFFFF;
  --color-100: #F0F0F0;
  --color-200: #EEEEEE;
  --color-300: #E0E0E0;
  --color-400: #BDBDBD;
  --color-500: #555555;
  --color-600: #757575;
  --color-700: #616161;
  --color-800: #424242;
  --color-900: #212121;
  --color-000: #000000;
  --border-color: #f0f0f0;
  --card-color: #ffffff;
  --body-color: #f9fbfd;
  --white-color: #ffffff;
  --sidebar-color: #fcfcfc;
  --text-color: #212529;
  --hc-font-color: #555555;
  --hc-bg-color: #f0f0f0;
  --primary-color: #6aab9c;
  --secondary-color: #f7b36b;
  --chart-color1: #6aab9c;
  --chart-color2: #f7b36b;
  --chart-color3: #97B0AA;
  --chart-color4: #4D6E94;
  --chart-color5: #81A1CA
}

[data-theme="dark"] {
  --dark-color: #ffffff;
  --color-fff: #000000;
  --color-100: #212121;
  --color-200: #262727;
  --color-300: #616161;
  --color-400: #757575;
  --color-500: #8f8f8f;
  --color-600: #BDBDBD;
  --color-700: #E0E0E0;
  --color-800: #EEEEEE;
  --color-900: #F0F0F0;
  --color-000: #FFFFFF;
  --border-color: #353535;
  --card-color: #262727;
  --body-color: #ffffff;
  --white-color: #ffffff;
  --sidebar-color: #fcfcfc;
  --text-color: #8f8f8f;
  --hc-font-color: #555555;
  --hc-bg-color: #f0f0f0
}




[class="theme-blush"] {
  --primary-color: #1A5D7A;
  --secondary-color: #ffffff;
  --body-color: #ffffff;
  --primary-gradient: linear-gradient(45deg, #f5ae8a, #ED5782)
}


.secondary-color {
  color: var(--secondary-color)
}

.secondary-color-fill {
  fill: var(--secondary-color)
}

html * {
  box-sizing: border-box
}

body {
  -moz-transition: ease 0.1s;
  -o-transition: ease 0.1s;
  -webkit-transition: ease 0.1s;
  transition: ease 0.1s;
  background-color: var(--body-color);
  color: var(--text-color);
  font-weight: 400;
  font-family: 'Mukta', "Open Sans", sans-serif;
  font-size: 14px,

}

body.font-opensans {
  font-family: "Open Sans", sans-serif
}

body.font-poppins {
  font-family: "Poppins", sans-serif;
  font-size: 15px
}

body.font-montserrat {
  font-family: "Montserrat", sans-serif;
  font-size: 15px
}

body.font-mukta {
  font-family: "Mukta", sans-serif
}

@media only screen and (max-width: 554px) {
  body body {
    font-size: 12px
  }
}

body a {
  color: var(--text-color);
  text-decoration: none
}

body a:hover {
  text-decoration: none;
  color: var(--secondary-color)
}

@media only screen and (max-width: 554px) {
  .btn-set-task {
    margin-top: 10px
  }
}

@media only screen and (min-width: 480px) {
  .mx-size {
    max-width: 320px
  }
}

@media only screen and (max-width: 480px) {
  .auth-detailblock strong {
    font-size: 12px
  }
}

.auth-h100 {
  height: calc(100vh - 192px)
}

@media only screen and (max-width: 1280px) {
  .auth-h100 {
    height: calc(100vh - 96px)
  }
}

@media only screen and (max-width: 640px) {
  .auth-h100 {
    height: auto
  }
}

@media only screen and (max-width: 768px) {
  .form-control-lg {
    min-height: calc(1.5em + 1rem + 2px) !important
  }
}

@media only screen and (max-width: 390px) {
  .invoice-set.nav-tabs .nav-link {
    width: 100px;
    text-align: center
  }
}

.ms-container .ms-list {
  border: 1px solid var(--border-color)
}

.ms-container .ms-selectable,
.ms-container .ms-selection {
  background: var(--card-color);
  color: var(--text-color)
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selectable {
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color)
}

@media only screen and (max-width: 500px) {

  .ms-container .ms-selectable,
  .ms-container .ms-selection {
    width: 100%;
    float: none
  }
}

@media only screen and (max-width: 500px) {
  .ms-container .ms-selection {
    margin-top: 40px
  }
}

.dropify-wrapper .dropify-message {
  font-size: 14px
}

.dropify-wrapper .dropify-message span.file-icon {
  font-size: 20px
}

@media only screen and (max-width: 390px) {
  .profile-fulldeatil {
    flex-direction: column
  }
}

@media only screen and (max-width: 390px) {
  .profile-fulldeatil .profile-info.border-start {
    border-left: 0px !important;
    padding-left: 0px !important
  }
}

@media only screen and (max-width: 390px) {
  .profile-fulldeatil .profile-av {
    text-align: left !important
  }
}

@media only screen and (max-width: 390px) {
  .profile-fulldeatil .about-info {
    justify-content: flex-start !important
  }
}

:not(pre)>code[class*="language-"],
pre[class*="language-"] {
  background: var(--color-100) !important
}

code[class*="language-"],
pre[class*="language-"] {
  text-shadow: none !important;
  color: var(--color-000) !important
}

.ico-title {
  font-size: 2em
}

.iconlist {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row
}

.iconlist .icon-holder {
  position: relative;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
  padding-bottom: 5px;
  border: 1px solid var(--border-color);
  transition: all 0.2s linear 0s
}

.iconlist .icon-holder:hover {
  background: var(--secondary-color);
  color: #ffffff
}

.iconlist .icon-holder:hover .icon i {
  color: #ffffff
}

.iconlist .icon-holder .icon {
  padding: 20px;
  text-align: center
}

.iconlist .icon-holder .icon i {
  font-size: 3em;
  color: var(--font-color)
}

.iconlist .icon-holder span {
  font-size: 14px;
  display: block;
  margin-top: 5px;
  border-radius: 3px
}

@media only screen and (max-width: 554px) {
  .w-sm-100 {
    width: 100%
  }
}

@media only screen and (max-width: 430px) {
  .profile-block {
    margin: 0 auto;
    padding-right: 0px !important
  }

  .profile-card .card-body {
    flex-direction: column
  }

  .profile-card .card-body .profile-av {
    padding-right: 0px !important
  }

  .profile-card .card-body .profile-av.w220 {
    width: 100%
  }

  .profile-card .card-body .profile-av .about-info {
    justify-content: center !important
  }

  .profile-card .card-body .profile-info {
    padding-left: 0px !important;
    border-color: transparent !important;
    text-align: center
  }

  .profile-card .card-body .profile-info .social {
    justify-content: center
  }

  .profile-card .card-body .profile-info .ct-btn-set {
    justify-content: center
  }
}

body.rtl_mode {
  direction: rtl;
  text-align: right
}

body.rtl_mode ul {
  padding-right: 0
}

body.rtl_mode .float-right {
  float: left !important
}

body.rtl_mode .text-end {
  text-align: left !important
}

body.rtl_mode .ms-auto {
  margin-left: unset !important;
  margin-right: auto !important
}

body.rtl_mode .me-auto {
  margin-right: unset !important;
  margin-left: auto !important
}

body.rtl_mode .end-0 {
  left: 0 !important;
  right: auto !important
}

body.rtl_mode .ms-1 {
  margin-right: .25rem !important
}

body.rtl_mode .ms-1,
body.rtl_mode .ms-sm-1,
body.rtl_mode .ms-md-1,
body.rtl_mode .ms-lg-1,
body.rtl_mode .ms-xl-1,
body.rtl_mode .ms-xxl-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important
}

body.rtl_mode .ms-2,
body.rtl_mode .ms-sm-2,
body.rtl_mode .ms-md-2,
body.rtl_mode .ms-lg-2,
body.rtl_mode .ms-xl-2,
body.rtl_mode .ms-xxl-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important
}

body.rtl_mode .ms-3,
body.rtl_mode .ms-sm-3,
body.rtl_mode .ms-md-3,
body.rtl_mode .ms-lg-3,
body.rtl_mode .ms-xl-3,
body.rtl_mode .ms-xxl-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important
}

body.rtl_mode .ms-4,
body.rtl_mode .ms-sm-4,
body.rtl_mode .ms-md-4,
body.rtl_mode .ms-lg-4,
body.rtl_mode .ms-xl-4,
body.rtl_mode .ms-xxl-5 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important
}

body.rtl_mode .ms-5,
body.rtl_mode .ms-sm-5,
body.rtl_mode .ms-md-5,
body.rtl_mode .ms-lg-5,
body.rtl_mode .ms-xl-5,
body.rtl_mode .ms-xxl-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important
}

body.rtl_mode .me-1,
body.rtl_mode .me-sm-1,
body.rtl_mode .me-md-1,
body.rtl_mode .me-lg-1,
body.rtl_mode .me-xl-1,
body.rtl_mode .me-xxl-1 {
  margin-left: 0.25rem !important;
  margin-right: 0 !important
}

body.rtl_mode .me-2,
body.rtl_mode .me-sm-2,
body.rtl_mode .me-md-2,
body.rtl_mode .me-lg-2,
body.rtl_mode .me-xl-2,
body.rtl_mode .me-xxl-2 {
  margin-left: 0.5rem !important;
  margin-right: 0 !important
}

body.rtl_mode .me-3,
body.rtl_mode .me-sm-3,
body.rtl_mode .me-md-3,
body.rtl_mode .me-lg-3,
body.rtl_mode .me-xl-3,
body.rtl_mode .me-xxl-3 {
  margin-left: 1rem !important;
  margin-right: 0 !important
}

body.rtl_mode .me-4,
body.rtl_mode .me-sm-4,
body.rtl_mode .me-md-4,
body.rtl_mode .me-lg-4,
body.rtl_mode .me-xl-4,
body.rtl_mode .me-xxl-4 {
  margin-left: 1.5rem !important;
  margin-right: 0 !important
}

body.rtl_mode .me-5,
body.rtl_mode .me-sm-5,
body.rtl_mode .me-md-5,
body.rtl_mode .me-lg-5,
body.rtl_mode .me-xl-5,
body.rtl_mode .me-xxl-5 {
  margin-left: 3rem !important;
  margin-right: 0 !important
}

body.rtl_mode .ps-1,
body.rtl_mode .ps-sm-1,
body.rtl_mode .ps-md-1,
body.rtl_mode .ps-lg-1,
body.rtl_mode .ps-xl-1,
body.rtl_mode .ps-xxl-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important
}

body.rtl_mode .ps-2,
body.rtl_mode .ps-sm-2,
body.rtl_mode .ps-md-2,
body.rtl_mode .ps-lg-2,
body.rtl_mode .ps-xl-2,
body.rtl_mode .ps-xxl-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important
}

body.rtl_mode .ps-3,
body.rtl_mode .ps-sm-3,
body.rtl_mode .ps-md-3,
body.rtl_mode .ps-lg-3,
body.rtl_mode .ps-xl-3,
body.rtl_mode .ps-xxl-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important
}

body.rtl_mode .ps-4,
body.rtl_mode .ps-sm-4,
body.rtl_mode .ps-md-4,
body.rtl_mode .ps-lg-4,
body.rtl_mode .ps-xl-4,
body.rtl_mode .ps-xxl-5 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important
}

body.rtl_mode .ps-5,
body.rtl_mode .ps-sm-5,
body.rtl_mode .ps-md-5,
body.rtl_mode .ps-lg-5,
body.rtl_mode .ps-xl-5,
body.rtl_mode .ps-xxl-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important
}

body.rtl_mode .pe-1,
body.rtl_mode .pe-sm-1,
body.rtl_mode .pe-md-1,
body.rtl_mode .pe-lg-1,
body.rtl_mode .pe-xl-1,
body.rtl_mode .pe-xxl-1 {
  padding-left: 0.25rem !important;
  padding-right: 0 !important
}

body.rtl_mode .pe-2,
body.rtl_mode .pe-sm-2,
body.rtl_mode .pe-md-2,
body.rtl_mode .pe-lg-2,
body.rtl_mode .pe-xl-2,
body.rtl_mode .pe-xxl-2 {
  padding-left: 0.5rem !important;
  padding-right: 0 !important
}

body.rtl_mode .pe-3,
body.rtl_mode .pe-sm-3,
body.rtl_mode .pe-md-3,
body.rtl_mode .pe-lg-3,
body.rtl_mode .pe-xl-3,
body.rtl_mode .pe-xxl-3 {
  padding-left: 1rem !important;
  padding-right: 0 !important
}

body.rtl_mode .pe-4,
body.rtl_mode .pe-sm-4,
body.rtl_mode .pe-md-4,
body.rtl_mode .pe-lg-4,
body.rtl_mode .pe-xl-4,
body.rtl_mode .pe-xxl-4 {
  padding-left: 1.5rem !important;
  padding-right: 0 !important
}

body.rtl_mode .pe-5,
body.rtl_mode .pe-sm-5,
body.rtl_mode .pe-md-5,
body.rtl_mode .pe-lg-5,
body.rtl_mode .pe-xl-5,
body.rtl_mode .pe-xxl-5 {
  padding-left: 3rem !important;
  padding-right: 0 !important
}

body.rtl_mode .border-start {
  border-left: 0px !important;
  border-right: 1px solid var(--border-color) !important
}

body.rtl_mode .border-end {
  border-left: 1px solid var(--border-color) !important;
  border-right: 0px !important
}

body.rtl_mode .dropdown-menu {
  text-align: right
}

body.rtl_mode .dropdown-menu-right {
  left: 0;
  right: auto;
  text-align: right
}

body.rtl_mode .modal-header .btn-close {
  margin: unset
}

body.rtl_mode .list-inline-item:not(:last-child) {
  margin-left: .5rem;
  margin-right: 0
}

body.rtl_mode .form-check {
  padding-right: 1.5em;
  padding-left: 0
}

body.rtl_mode .form-check .form-check-input {
  float: right;
  margin-left: 10px;
  margin-right: -1.5em
}

body.rtl_mode .breadcrumb-item+.breadcrumb-item {
  padding-right: .5rem;
  padding-left: 0
}

body.rtl_mode .breadcrumb-item+.breadcrumb-item:before {
  float: right;
  padding-left: .5rem;
  padding-right: 0
}

body.rtl_mode .modal.fade .modal-dialog-vertical {
  transform: translateX(100%)
}

body.rtl_mode .modal.show .modal-dialog-vertical {
  transform: translateX(0)
}

body.rtl_mode .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
body.rtl_mode .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

body.rtl_mode .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}

body.rtl_mode .btn-group .btn:first-child {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}

body.rtl_mode .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
body.rtl_mode .btn-group>.btn-group:not(:last-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

body.rtl_mode .btn-group>.btn:nth-child(n+3),
body.rtl_mode .btn-group>:not(.btn-check)+.btn,
body.rtl_mode .btn-group>.btn-group:not(:first-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}

body.rtl_mode .sidebar-mini-btn .fa-arrow-left {
  transform: rotate(180deg)
}

body.rtl_mode .dropdown-toggle::after {
  margin-left: unset;
  margin-right: 0.255em
}

body.rtl_mode div.dataTables_wrapper div.dataTables_filter {
  text-align: end
}

@media only screen and (min-width: 992px) {
  body.rtl_mode .me-lg-0 {
    margin-left: 0 !important
  }

  body.rtl_mode .pe-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important
  }
}

@media only screen and (min-width: 768px) {
  body.rtl_mode .ms-md-5 {
    margin-right: 3rem !important
  }

  body.rtl_mode .text-md-start {
    text-align: right !important
  }
}

body.rtl_mode .timeline-item {
  border-left: 0;
  border-right: 2px solid var(--border-color)
}

body.rtl_mode .timeline-item:after {
  right: -5px;
  left: auto
}

body.rtl_mode .apexcharts-canvas,
body.rtl_mode .owl-carousel {
  direction: ltr
}

body.rtl_mode .owl-carousel {
  text-align: left
}

body.rtl_mode .owl-carousel .ms-2 {
  margin-left: 0.5rem !important
}

body.rtl_mode .sidebar {
  margin-left: 0px !important;
  margin-right: 25px !important
}

body.rtl_mode .sidebar.sidebar-mini .form-switch.theme-rtl {
  padding-left: 0px;
  padding-right: 1.5em
}

body.rtl_mode .sidebar.sidebar-mini .sidebar-mini-btn span {
  margin-right: 0px !important
}

body.rtl_mode .sidebar.sidebar-mini .sidebar-mini-btn .icofont-bubble-right:before {
  content: "\ea64"
}

body.rtl_mode .sidebar.sidebar-mini .menu-list .m-link:hover span {
  left: unset;
  right: 101px
}

body.rtl_mode .sidebar.sidebar-mini .menu-list .sub-menu {
  left: unset;
  right: 101px
}

body.rtl_mode .sidebar.sidebar-mini .menu-list .sub-menu li a {
  justify-content: flex-end
}

body.rtl_mode .sidebar.sidebar-mini .brand-icon .logo-text {
  padding-left: 0px;
  padding-right: 0px
}

body.rtl_mode .sidebar .form-switch.theme-rtl {
  padding-left: 0px;
  padding-right: 0.8em
}

body.rtl_mode .sidebar .sidebar-mini-btn span {
  margin-right: 0px !important
}

body.rtl_mode .sidebar .sidebar-mini-btn .icofont-bubble-right:before {
  content: "\ea65"
}

body.rtl_mode .sidebar .brand-icon .logo-text {
  padding-left: 0px;
  padding-right: 15px
}

body.rtl_mode .sidebar.open {
  left: unset;
  right: 0;
  margin-right: 0px !important
}

body.rtl_mode .sidebar .list-unstyled {
  padding-right: 0px
}

body.rtl_mode .sidebar .sub-menu {
  padding-right: 0px
}

body.rtl_mode .rightchatbox .friend .status {
  left: 17px
}

body.rtl_mode .icofont-hand-drawn-right:before {
  content: "\ea87"
}

body.rtl_mode .todo_list li label:before {
  left: unset;
  right: 0px
}

body.rtl_mode .rightchatbox #close {
  left: unset;
  right: 10px
}

body.rtl_mode .fc-media-screen.fc-direction-ltr {
  flex-direction: rtl;
  text-align: right
}

body.rtl_mode .fc-media-screen.fc-direction-ltr .fc-toolbar {
  flex-direction: row-reverse
}

body.rtl_mode .dropdown-item .me-2 {
  margin-left: 0px !important;
  margin-right: .5rem !important
}

body.rtl_mode .dropdown-item .me-3 {
  margin-left: 0px !important;
  margin-right: 1rem !important
}

body.rtl_mode .dropdown-menu-sm-end[data-bs-popper],
body.rtl_mode .dropdown-menu-end {
  right: auto !important;
  left: 0 !important
}

body.rtl_mode .dropdown-menu-end {
  --bs-position: start
}

body.rtl_mode .icofont-ui-add.me-2,
body.rtl_mode .icofont-plus-circle.me-2 {
  margin-left: 0px !important;
  margin-right: 0.5rem !important
}

body.rtl_mode .btn-postion {
  right: unset;
  left: 15px
}

body.rtl_mode .icofont-dotted-right:before {
  content: "\ea78"
}

body.rtl_mode .list-group i.me-3 {
  margin-left: 0px !important;
  margin-right: 1rem !important
}

body.rtl_mode button i.me-2 {
  margin-left: 0px !important;
  margin-right: 0.5rem !important
}

body.rtl_mode .card-chat.open {
  left: unset;
  right: 0
}

body.rtl_mode .side-navbar {
  padding-left: 0;
  padding-right: 2rem
}

body.rtl_mode .side-navbar ul {
  padding-left: 0;
  padding-right: 2rem
}

body.rtl_mode .doc .me-2 {
  margin-left: 0px !important;
  margin-right: 0.5rem !important
}

body.rtl_mode .doc ul {
  padding-left: 0;
  padding-right: 2rem
}

body.rtl_mode .card.card-callout {
  border-left-width: 1px !important;
  border-left-color: var(--border-color);
  border-right-color: var(--primary-color);
  border-right-width: .4rem !important
}

body.rtl_mode div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0px;
  margin-right: 0.5em
}

body.rtl_mode .profile-card .me-2 {
  margin-right: .5rem !important
}

@media only screen and (max-width: 554px) {
  body.rtl_mode .project-tab .prtab-set {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 10px;
    margin-bottom: 10px
  }
}

body.rtl_mode table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child,
body.rtl_mode table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child {
  padding-right: 30px;
  padding-left: 0px
}

body.rtl_mode table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
body.rtl_mode table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
  left: unset;
  right: 4px
}

body.rtl_mode .modal.right.fade .modal-dialog {
  right: auto;
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out
}

body.rtl_mode .modal.right.fade.show .modal-dialog {
  right: auto;
  left: 10px
}

@media only screen and (max-width: 767px) {
  body.rtl_mode .modal.right.fade.show .modal-dialog {
    left: 0px
  }
}


.border,
.border-top,
.border-right,
.border-bottom,
.border-left,
.dropdown-divider {
  border-color: var(--border-color)
}

.container-xxl {
  max-width: 1560px
}

.font-weight-bold {
  font-weight: bold
}

.table>:not(caption)>*>* {
  padding: .8rem .8rem
}

.zindex-fixed {
  z-index: 1030
}

.zindex-popover {
  z-index: 1070
}

.zindex-modal {
  z-index: 1055
}

.small-xs {
  font-size: 13px !important
}

.bootstrap-tagsinput {
  width: 100%;
  box-shadow: none
}

.form-label {
  font-weight: 600
}

.form-control,
.form-select {
  border-color: var(--border-color);
  background-color: var(--card-color);
  color: var(--text-color);
  font-size: 16px
}

.form-control::placeholder,
.form-select::placeholder {
  color: var(--color-400)
}

.form-control:focus,
.form-select:focus {
  box-shadow: 0 0 10px rgba(33, 37, 41, 0.15);
  background-color: var(--card-color);
  border-color: var(--primary-color);
  color: var(--text-color)
}

.form-control.form-control-lg,
.form-select.form-control-lg {
  min-height: calc(1.5em + 1.8rem + 2px)
}

.input-group-text {
  border-color: var(--border-color);
  background-color: var(--color-200);
  color: var(--text-color)
}

.ms-container {
  width: 100%
}

.badge-info {
  background: var(--primary-color)
}

.form-switch .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--border-color)
}

.big-search {
  color: var(--color-fff)
}

.big-search:focus {
  color: var(--color-fff)
}

.btn-link {
  color: var(--text-color)
}

.btn-link:hover {
  color: var(--secondary-color)
}

.bg-white {
  color: var(--text-color)
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
  line-height: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
  line-height: calc(1.325rem + 0.9vw) !important
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
  line-height: calc(1.3rem + 0.6vw) !important
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
  line-height: calc(1.275rem + 0.3vw) !important
}

.btn {
  font-size: 14px;
  color: var(--text-color)
}

.btn:hover {
  color: var(--text-color)
}

.btn.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color)
}

.btn.btn-primary.active {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color)
}

.btn.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color)
}

.btn.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--primary-color)
}

.btn.btn-outline-primary.active {
  background-color: var(--primary-color);
  color: var(--white-color)
}

.btn.btn-outline-secondary {
  border-color: var(--border-color)
}

.btn.btn-outline-secondary:hover {
  color: #fff;
  background-color: var(--secondary-color)
}

.btn.btn-outline-secondary:hover i {
  color: #fff !important
}

.btn.btn-outline-success {
  color: #4DCA88;
  border-color: #4DCA88
}

.btn.btn-outline-success:hover {
  background-color: #4DCA88;
  color: #fff
}

.btn.btn-outline-danger {
  color: #FC5A69;
  border-color: #FC5A69
}

.btn.btn-outline-danger:hover {
  background-color: #FC5A69;
  color: #fff
}

.btn.btn-outline-info {
  color: #4FB8C9;
  border-color: #4FB8C9
}

.btn.btn-outline-info:hover {
  background-color: #4FB8C9;
  color: #fff
}

.btn.btn-dark {
  color: var(--white-color)
}

.btn.btn-secondary {
  color: var(--white-color)
}

.btn.btn-lg,
.btn-group-lg>.btn {
  padding: .7rem 1rem
}

.btn-close {
  color: var(--text-color)
}

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px
}

.avatar.xs {
  width: 16px;
  min-width: 16px;
  height: 16px
}

.avatar.sm {
  width: 22px !important;
  min-width: 22px !important;
  height: 22px !important
}

.avatar.sm i {
  font-size: 12px;
  line-height: 12px;
  vertical-align: text-top
}

.avatar.lg {
  width: 56px;
  min-width: 56px;
  height: 56px
}

.avatar.xl {
  width: 80px;
  min-width: 80px;
  height: 80px
}

.avatar.xxl {
  width: 120px;
  min-width: 120px;
  height: 120px
}

.avatar.no-thumbnail {
  background-color: var(--color-300);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center
}

.avatar-list {
  margin: 0 0 -.5rem;
  padding: 0;
  font-size: 0
}

.avatar-list .avatar {
  color: var(--secondary-color);
  font-weight: 600;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  display: inline-block;
  background: var(--primary-color) no-repeat center/cover;
  position: relative;
  vertical-align: bottom;
  font-size: .875rem;
  user-select: none;
  min-width: 2rem
}

.avatar-list .avatar:not(:last-child) {
  margin-right: .5rem
}

.avatar-list.list-inlineblock li {
  display: inline-block
}

.avatar-list-stacked .avatar {
  margin-right: -.8em !important;
  box-shadow: 0 0 0 2px #fff
}

@media only screen and (max-width: 490px) {
  .header .avatar-list-stacked {
    display: none
  }
}

@media only screen and (min-width: 490px) {
  .add-member-top {
    display: none
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: rgba(33, 37, 41, 0.1)
}

.dropdown-item {
  color: var(--text-color)
}

.dropdown-item:hover {
  color: var(--secondary-color)
}

.dropdown-menu {
  font-size: 15px;
  color: var(--text-color);
  background-color: var(--body-color)
}

.dropdown-menu li {
  margin: 5px 0px
}

.dropdown-menu .dropdown-item {
  color: var(--text-color)
}

.dropdown-menu .dropdown-item.active {
  color: var(--secondary-color);
  background-color: var(--primary-color)
}

.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus {
  background-color: var(--primary-color);
  color: var(--secondary-color)
}

.dropdown-menu .dropdown-item:hover {
  color: var(--secondary-color)
}

.modal {
  right: 0;
  left: auto;
  z-index: 99999
}

.modal.fade .modal-dialog-vertical {
  transform: translateX(-100%)
}

.modal.show .modal-dialog-vertical {
  transform: translateX(0)
}

.modal .modal-dialog-vertical {
  margin: 0;
  height: 100%
}

.modal .modal-dialog-vertical .modal-content {
  height: 100%
}

.modal .modal-header,
.modal .modal-footer {
  border-color: var(--border-color)
}

.modal .modal-content {
  background-color: var(--body-color)
}

.modal.right {
  z-index: 99999;
  margin-top: 25px;
  margin-bottom: 25px
}

@media only screen and (max-width: 1279px) {
  .modal.right {
    margin-top: 0px;
    margin-bottom: 0px
  }
}

.modal.right .modal-dialog {
  height: calc(100vh - 50px) !important;
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0)
}

@media only screen and (max-width: 1279px) {
  .modal.right .modal-dialog {
    height: 100vh !important
  }
}

@media only screen and (max-width: 767px) {
  .modal.right .modal-dialog {
    height: 100vh !important
  }
}

.modal.right .modal-content {
  height: 100%;
  border-radius: 1.1rem !important
}

@media only screen and (max-width: 1279px) {
  .modal.right .modal-content {
    border-radius: 0px !important
  }
}

.modal.right .modal-content .setting-theme,
.modal.right .modal-content .dynamic-block,
.modal.right .modal-content .setting-font,
.modal.right .modal-content .sidebar-gradient {
  border-bottom: 1px solid #e2e2e2
}

.modal.right .modal-content .setting-font .list-group .list-group-item,
.modal.right .modal-content .setting-mode .list-group .list-group-item,
.modal.right .modal-content .dynamic-block .list-group .list-group-item {
  background-color: transparent;
  border-color: transparent;
  padding: 0px !important;
  margin-bottom: 5px
}

.modal.right .modal-content .font_setting .form-check-input:checked {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  background-image: inherit
}

.modal.right .modal-content .setting-mode .form-check-input:checked {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color)
}

.modal.right .modal-content .dynamic-block .list-group-item .avatar.xs {
  width: 25px;
  height: 25px;
  min-width: 25px;
  border-radius: 5px !important
}

@media only screen and (max-width: 767px) {
  .modal.right .modal-content .modal-footer {
    display: none
  }
}

.modal.right .modal-body {
  overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto
}

.modal.right .modal-body::-webkit-scrollbar {
  width: 0
}

.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out
}

.modal.right.fade.show .modal-dialog {
  right: 10px
}

@media only screen and (max-width: 1279px) {
  .modal.right.fade.show .modal-dialog {
    right: 0px
  }
}

.breadcrumb {
  background-color: var(--color-200)
}

.nav-link {
  padding: 0.5rem 1rem
}

.navbar-light .navbar-nav .nav-link {
  color: var(--color-500)
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: var(--primary-color)
}

.nav-tabs {
  border-color: var(--border-color)
}

.nav-tabs.tab-custom {
  border: 1px solid rgba(255, 255, 255, 0.2)
}

.nav-tabs.tab-custom .nav-link {
  color: #fff;
  opacity: 0.7
}

.nav-tabs.tab-custom .nav-link.active {
  opacity: 1;
  color: var(--primary-color)
}

.nav-tabs.tab-card .nav-link {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background-color: transparent;
  border-bottom: 3px solid transparent;
  color: var(--color-500)
}

.nav-tabs.tab-card .nav-link.active {
  border-color: var(--primary-color)
}

.nav-tabs.tab-body-header {
  border: 1px solid Var(--primary-color);
  overflow: hidden
}

.nav-tabs.tab-body-header .nav-link {
  border: 0;
  margin: 0;
  border-radius: 0;
  color: var(--color-500)
}

.nav-tabs.tab-body-header .nav-link:hover {
  color: var(--primary-color)
}

.nav-tabs.tab-body-header .nav-link.active {
  background-color: var(--primary-color);
  color: #fff
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--primary-color)
}

.nav-pills .nav-link {
  color: var(--text-color)
}

.border-dark {
  border-color: var(--text-color) !important
}

.border-start {
  border-color: var(--border-color) !important
}

.list-group-custom .list-group-item,
.list-group .list-group-item {
  background-color: var(--card-color);
  border-color: var(--border-color);
  color: var(--text-color)
}

.list-group-custom .list-group-item.active,
.list-group .list-group-item.active {
  background-color: var(--primary-color)
}

.progress {
  background-color: var(--color-100)
}

.progress.plan-active {
  background-color: var(--color-300)
}

.table {
  border-color: var(--border-color)
}

.table tr {
  vertical-align: middle
}

.table tr th {
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 12px
}

.table tr td {
  border-color: var(--border-color);
  color: var(--text-color)
}

.table tr:hover td {
  color: var(--color-800)
}

.table .table-dark tr th {
  color: var(--white-color)
}

.table.doc-table tr th {
  border-color: var(--border-color)
}

.page-item.disabled .page-link {
  background-color: var(--border-color);
  border-color: var(--border-color)
}

.page-item .page-link {
  background-color: var(--card-color);
  border-color: var(--border-color);
  color: var(--primary-color)
}

.page-item.active .page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color)
}

.side-navbar li {
  margin-bottom: 5px
}

.side-navbar li a {
  color: var(--text-color)
}

.btn-outline-secondary.btn-block {
  color: #fff !important
}

.navbar-toggler:focus {
  box-shadow: none
}

input[type="date"],
input[type="time"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-width: 95%
}

.bootstrap-tagsinput,
.dropify-wrapper {
  border: 1px solid var(--border-color);
  background-color: var(--card-color)
}

.no-resize {
  resize: none
}

.text-primary {
  color: var(--primary-color) !important
}

.bg-primary {
  background: var(--primary-color) !important
}

.fill-primary {
  fill: var(--primary-color) !important
}

.text-secondary {
  color: var(--secondary-color) !important
}

.bg-secondary {
  background: var(--secondary-color) !important
}

.bg-primary-gradient {
  background: var(--primary-gradient) !important;
  color: #fff
}

.color-defult {
  color: #212529 !important
}

.text-muted {
  color: #9a9b9d !important
}

.link-secondary {
  color: var(--secondary-color) !important
}

.btn-success {
  background-color: #4DCA88;
  border-color: #4DCA88
}

.btn-danger {
  background-color: #FC5A69;
  border-color: #FC5A69
}

.btn-warning {
  background-color: #FFBA33;
  border-color: #FFBA33
}

.btn-info {
  background-color: #4FB8C9;
  border-color: #4FB8C9
}

.chart-color1 {
  background-color: var(--chart-color1) !important
}

.chart-color2 {
  background-color: var(--chart-color2) !important
}

.chart-color3 {
  background-color: var(--chart-color3) !important
}

.chart-color4 {
  background-color: var(--chart-color4) !important
}

.chart-color5 {
  background-color: var(--chart-color5) !important
}

.border,
.border-left,
.border-top,
.border-right,
.border-bottom {
  border-color: var(--color-100) !important
}

.vh100 {
  height: 100vh
}

.w30 {
  width: 30px
}

.w60 {
  width: 60px
}

.w90 {
  width: 90px
}

.w120 {
  width: 120px
}

.w220 {
  width: 220px
}

.w240 {
  width: 240px
}

.w280 {
  width: 280px
}

.w380 {
  width: 380px
}

.w-30 {
  width: 30%
}

.min-height-220 {
  min-height: 220px
}

.color-fff {
  color: var(--color-fff)
}

.color-100 {
  color: var(--color-100)
}

.color-200 {
  color: var(--color-200)
}

.color-300 {
  color: var(--color-300)
}

.color-400 {
  color: var(--color-400)
}

.color-600 {
  color: var(--color-600)
}

.color-700 {
  color: var(--color-700)
}

.color-800 {
  color: var(--color-800)
}

.color-900 {
  color: var(--color-900)
}

.color-000 {
  color: var(--color-000)
}

.color-defult-white {
  color: #fff
}

.color-bg-fff {
  background-color: var(--color-fff) !important
}

.color-bg-100 {
  background-color: var(--color-100) !important
}

.color-bg-200 {
  background-color: var(--color-200) !important
}

.color-bg-300 {
  background-color: var(--color-300) !important
}

.color-bg-400 {
  background-color: var(--color-400) !important
}

.color-bg-600 {
  background-color: var(--color-600) !important
}

.color-bg-700 {
  background-color: var(--color-700) !important
}

.color-bg-800 {
  background-color: var(--color-800) !important
}

.color-bg-900 {
  background-color: var(--color-900) !important
}

.color-bg-000 {
  background-color: var(--color-000) !important
}

.bg-dark {
  background-color: var(--primary-color) !important
}

.bg-dark-defualt {
  background-color: #212529 !important
}

.bg-lightyellow {
  background-color: #ffe28c !important
}

.bg-lightblue {
  background-color: #a7daff !important
}

.bg-lightgreen {
  background-color: #a8d7e0 !important
}

.light-warning-bg {
  background-color: #fff4de !important
}

.light-primary-bg {
  background-color: #e1f0ff !important
}

.light-info-bg {
  background-color: #eee5ff !important
}

.light-danger-bg {
  background-color: #F1C8DB !important
}

.light-success-bg {
  background-color: #A0D9B4 !important
}

.light-orange-bg {
  background-color: #FFAA8A !important
}

.bg-info-light {
  background-color: #9AE8FF !important
}

.bg-santa-fe {
  background-color: #B87C4C !important
}

.bg-careys-pink {
  background-color: #CF9EAC !important
}

.bg-lavender-purple {
  background-color: #A883BA !important
}

.bg-video {
  background-color: #1a1e2b !important
}

.text-blue {
  color: #0d6efd !important
}

.color-lightyellow {
  color: #ffe28c !important
}

.color-lightblue {
  color: #a7daff !important
}

.color-lightgreen {
  color: #a8d7e0 !important
}

.color-light-success {
  color: #A0D9B4 !important
}

.color-light-orange {
  color: #FFAA8A !important
}

.color-info-light {
  color: #9AE8FF !important
}

.color-santa-fe {
  color: #B87C4C !important
}

.color-careys-pink {
  color: #CF9EAC !important
}

.color-lavender-purple {
  color: #A883BA !important
}

.color-danger {
  color: #F1C8DB !important
}

.img-overlay:before {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.line-lightyellow {
  position: relative
}

.line-lightyellow:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #ffe28c !important
}

.line-lightblue {
  position: relative
}

.line-lightblue:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #a7daff !important
}

.line-lightgreen {
  position: relative
}

.line-lightgreen:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #a8d7e0 !important
}

.line-light-success {
  position: relative
}

.line-light-success:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #A0D9B4 !important
}

.line-light-orange {
  position: relative
}

.line-light-orange:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #FFAA8A !important
}

.line-info-light {
  position: relative
}

.line-info-light:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #9AE8FF !important
}

.line-santa-fe {
  position: relative
}

.line-santa-fe:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #B87C4C !important
}

.line-careys-pink {
  position: relative
}

.line-careys-pink:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #CF9EAC !important
}

.line-lavender-purple {
  position: relative
}

.line-lavender-purple:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #A883BA !important
}

.line-height-custom {
  line-height: 24px
}

.line-height-sm {
  line-height: 15px
}

.small-14 {
  font-size: 14px
}

.small-11 {
  font-size: 11px
}

.btn-postion {
  position: absolute;
  right: 15px;
  top: -50px
}

@media only screen and (max-width: 640px) {
  .btn-postion {
    position: unset;
    right: unset;
    top: unset
  }
}

.no-bg {
  background-color: transparent !important
}

.pointer {
  cursor: pointer
}

.chooser {
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  width: 100%
}

.dividers {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center
}

.dividers:after,
.dividers:before {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid var(--border-color);
  content: "";
  margin-top: .0625rem
}

.dividers::before {
  margin-right: 1.5rem
}

.dividers::after {
  margin-left: 1.5rem
}

.dividers-block {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25
}

.dividers-block:not([size]) {
  height: 1px
}

.task_status span {
  width: 15px;
  height: 15px;
  display: block
}

.btn-close {
  background-color: var(--white-color)
}

[data-theme="dark"] .btn-light {
  background-color: var(--body-color);
  border-color: var(--body-color)
}

[data-theme="dark"] .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(255, 255, 255, 0.23) !important
}

[data-theme="dark"] .shadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.19) !important
}

[data-theme="dark"] .text-muted {
  color: #5b5b5b !important
}

[data-theme="dark"] .table .table-dark {
  --bs-table-bg: var(--primary-color)
}

[data-theme="dark"] .table .table-dark tr th {
  color: var(--text-color);
  background-color: #313131
}

[data-theme="dark"] .light-warning-bg {
  background-color: #ead09d !important
}

[data-theme="dark"] .big-search {
  color: var(--text-color)
}

[data-theme="dark"] .big-search:focus {
  color: var(--text-color)
}

[data-theme="dark"] .timeline-item {
  border-left: 2px solid var(--border-color) !important
}

[data-theme="dark"] .timeline-item .avatar {
  color: var(--border-color)
}

[data-theme="dark"] .border,
[data-theme="dark"] .border-left,
[data-theme="dark"] .border-top,
[data-theme="dark"] .border-right,
[data-theme="dark"] .border-bottom {
  border-color: var(--border-color) !important
}

[data-theme="dark"] .table-hover>tbody>tr:hover {
  --bs-table-accent-bg: var(--border-color)
}

[data-theme="dark"] .bg-lightyellow,
[data-theme="dark"] .bg-lightblue,
[data-theme="dark"] .bg-lightgreen,
[data-theme="dark"] .light-warning-bg,
[data-theme="dark"] .light-primary-bg,
[data-theme="dark"] .light-info-bg,
[data-theme="dark"] .light-danger-bg,
[data-theme="dark"] .light-success-bg,
[data-theme="dark"] .light-orange-bg,
[data-theme="dark"] .bg-info-light,
[data-theme="dark"] .bg-santa-fe,
[data-theme="dark"] .bg-careys-pink,
[data-theme="dark"] .bg-lavender-purple {
  color: var(--body-color)
}

[data-theme="dark"] .top-perfomer h6,
[data-theme="dark"] .top-perfomer .h6 {
  color: var(--text-color)
}

[data-theme="high-contrast"] .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(255, 255, 255, 0.23) !important
}

[data-theme="high-contrast"] .shadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.19) !important
}

[data-theme="high-contrast"] .border,
[data-theme="high-contrast"] .border-top,
[data-theme="high-contrast"] .border-right,
[data-theme="high-contrast"] .border-bottom,
[data-theme="high-contrast"] .border-left,
[data-theme="high-contrast"] .dropdown-divider {
  border-color: var(--border-color) !important
}

[data-theme="high-contrast"] .basic-custome-color .table tr:hover td {
  color: var(--white-color)
}

[data-theme="high-contrast"] .variants-custome-color .table tbody tr td,
[data-theme="high-contrast"] .variants-custome-color .table tbody tr th {
  color: var(--body-color)
}

[data-theme="high-contrast"] .bg-lightyellow,
[data-theme="high-contrast"] .bg-lightblue,
[data-theme="high-contrast"] .bg-lightgreen,
[data-theme="high-contrast"] .light-warning-bg,
[data-theme="high-contrast"] .light-primary-bg,
[data-theme="high-contrast"] .light-info-bg,
[data-theme="high-contrast"] .light-danger-bg,
[data-theme="high-contrast"] .light-success-bg,
[data-theme="high-contrast"] .light-orange-bg,
[data-theme="high-contrast"] .bg-info-light,
[data-theme="high-contrast"] .bg-santa-fe,
[data-theme="high-contrast"] .bg-careys-pink,
[data-theme="high-contrast"] .bg-lavender-purple {
  color: var(--body-color)
}

[data-theme="high-contrast"] .avatar.no-thumbnail {
  background-color: #212529
}

[data-theme="high-contrast"] .form-control::placeholder {
  color: var(--white-color)
}

[data-theme="high-contrast"] .modal .modal-content {
  background-color: #1b1b1b
}

[data-theme="high-contrast"] .modal .card {
  border-color: var(--hc-bg-color);
  background-color: var(--white-color)
}

[data-theme="high-contrast"] .border-dark {
  border-color: var(--color-200) !important
}

[data-theme="high-contrast"] .input-group-text {
  color: var(--card-color)
}

[data-theme="high-contrast"] #myProjectTable>tbody>tr:hover td,
[data-theme="high-contrast"] #myDataTable>tbody>tr:hover td,
[data-theme="high-contrast"] #patient-table>tbody>tr:hover td,
[data-theme="high-contrast"] #myCartTable>tbody>tr:hover td,
[data-theme="high-contrast"] .checkout-table tr:hover td {
  color: var(--white-color)
}

.theme-tradewind .sidebar .menu-list .m-link:hover,
.theme-tradewind .sidebar .menu-list .m-link.active,
.theme-tradewind .sidebar .menu-list .ms-link:hover,
.theme-tradewind .sidebar .menu-list .ms-link.active {
  color: #ffe4c7
}

.dropdown-animation {
  opacity: 0;
  transform: translateY(15px);
  -webkit-animation: eliment-animation .2s ease-in-out .33333s;
  animation: eliment-animation .2s ease-in-out .33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: .05s;
  animation-delay: .05s
}

@-webkit-keyframes eliment-animation {
  to {
    opacity: 1;
    transform: translateX(0)
  }
}

@keyframes eliment-animation {
  to {
    opacity: 1;
    transform: translateX(0)
  }
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 0.5
  }

  100% {
    transform: scale(2);
    opacity: 0
  }
}

.lift {
  transition: box-shadow .25s ease,
    transform .25s ease
}

.lift:focus,
.lift:hover {
  box-shadow: 0 6px 1rem rgba(33, 37, 41, 0.1), 0 0.5rem 1rem -0.75rem rgba(33, 37, 41, 0.1) !important;
  transform: translate3d(0, -3px, 0)
}

.pulse .pulse-ring {
  border-color: var(--primary-color);
  display: block;
  position: absolute;
  -webkit-animation: animation-pulse 3.5s ease-out;
  animation: animation-pulse 3.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-style: solid;
  top: -5px;
  left: 4px;
  border-radius: 45px;
  height: 45px;
  width: 45px;
  opacity: 0;
  border-width: 5px
}

.pulse::after {
  display: none
}

@-webkit-keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0
  }

  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0
  }

  65% {
    opacity: 1
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0
  }
}

@keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0
  }

  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0
  }

  65% {
    opacity: 1
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0
  }
}

.choose-skin li {
  position: relative;
  cursor: pointer;
  font-size: 11px
}

.choose-skin li:last-child {
  margin-right: 0px
}

.choose-skin li.active:after {
  font-family: IcoFont;
  font-style: normal;
  content: '\eed6';
  position: absolute;
  font-size: 30px;
  top: -7px;
  left: 5px;
  color: #fff
}

.choose-skin li.active .dynamic {
  background: var(--primary-color);
  color: #fff
}

.choose-skin li div {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 14px;
  border-radius: 5px
}

.choose-skin li span {
  position: relative;
  bottom: 7px;
  left: 5px
}

.choose-skin .tradewind {
  background-color: #6aab9c
}

.choose-skin .monalisa {
  background-color: #FF7B89
}

.choose-skin .blue {
  background-color: #7258db
}

.choose-skin .blush {
  background-color: #ED5782
}

.choose-skin .indigo {
  background-color: #484c7f
}

.choose-skin .purple {
  background-color: #484c7f
}

.choose-skin .red {
  background-color: #FF1843
}

.choose-skin .orange {
  background-color: #f8b500
}

.choose-skin .yellow {
  background-color: #FFBA33
}

.choose-skin .green {
  background-color: #7EB93F
}

.choose-skin .cyan {
  background-color: #00bdaa
}

.custom_setting .dynamic-block .choose-skin li {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: .25rem;
  font-size: 16px
}

.custom_setting .dynamic-block .choose-skin li.active .dynamic {
  background-color: var(--secondary-color)
}

.custom_setting .dynamic-block .choose-skin li.active:after {
  content: ''
}

.custom_setting .dynamic-block .choose-skin li div {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center
}

.colorpicker {
  z-index: 99999 !important
}

.theme-dynamic .dt-setting {
  display: block
}

.dt-setting {
  display: none
}

.setting-list li,
.layout_setting li {
  margin-bottom: 15px
}

.rtl_mode .right-sidebar {
  left: -300px;
  right: auto
}

.rtl_mode .right-sidebar.open {
  left: 0;
  right: auto
}

#ebazar-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row
}

.navigation {
  background-color: var(--card-color);
  width: 60px;
  height: 100vh;
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  z-index: 9
}

@media only screen and (max-width: 991px) {
  .navigation {
    width: 100%;
    height: 60px;
    flex-direction: row;
    align-items: center;
    position: fixed;
    overflow: hidden
  }

  .navigation .brand-icon svg {
    width: 22px;
    margin-right: 20px
  }

  .navigation .navbar-nav {
    justify-content: flex-end !important;
    padding-right: 50px;
    flex-direction: row !important
  }

  .navigation .navbar-nav .flex-grow-1 {
    flex-grow: unset !important
  }

  .navigation .navbar-nav .nav-link {
    margin-left: 5px
  }

  .navigation .navbar-nav .menu-toggle {
    position: fixed;
    right: 6px
  }
}

.sidebar {
  -moz-transition: ease 0.1s;
  -o-transition: ease 0.1s;
  -webkit-transition: ease 0.1s;
  transition: ease 0.1s;
  background: var(--primary-color);
  z-index: 99999;
  margin: 25px;
  height: calc(100vh - 50px);
  width: 260px;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-radius: 1.1rem;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1
}

@media only screen and (max-width: 1279px) {

  /* .sidebar {
      position: fixed;
      left: -400px;
      height: 100vh;
      margin: 0px;
      border-radius: 0px
    } */
  .sidebar {
    overflow-y: auto;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: auto
  }

  .sidebar::-webkit-scrollbar {
    width: 0
  }
}

@media only screen and (min-width: 1279px) {
  .sidebar {
    overflow-y: auto;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: auto
  }

  .sidebar::-webkit-scrollbar {
    width: 0
  }
}

@media only screen and (max-width: 375px) {
  .sidebar {
    height: 100%
  }
}

.sidebar .form-switch label {
  color: var(--white-color);
  font-size: 15px;
  padding-left: 10px
}

.sidebar .form-switch.theme-rtl {
  padding-left: 2em
}

.sidebar .sidebar-title {
  color: var(--white-color)
}

.sidebar .menu-list {
  list-style: none;
  padding: 0;
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none
}

.sidebar .menu-list::-webkit-scrollbar {
  width: 4px
}

.sidebar .menu-list:hover::-webkit-scrollbar-thumb {
  visibility: visible
}

.sidebar .menu-list:hover::-webkit-scrollbar-track {
  visibility: visible
}

.sidebar .menu-list::-webkit-scrollbar-track {
  visibility: hidden;
  background: transparent
}

.sidebar .menu-list::-webkit-scrollbar-thumb {
  visibility: hidden;
  opacity: 0.2;
  background: var(--secondary-color);
  border-radius: 5px
}

.sidebar .menu-list::-webkit-scrollbar-thumb:hover {
  opacity: 1
}

.sidebar .menu-list li[aria-expanded="true"] .m-link {
  color: var(--secondary-color)
}

.sidebar .menu-list li[aria-expanded="true"] .arrow:before {
  content: "\f10c"
}

.sidebar .menu-list li[aria-expanded="true"] .sub-menu:before {
  background-color: var(--secondary-color)
}

.sidebar .menu-list .m-link,
.sidebar .menu-list .ms-link {
  display: flex
}

.sidebar .menu-list .m-link:hover,
.sidebar .menu-list .m-link.active,
.sidebar .menu-list .ms-link:hover,
.sidebar .menu-list .ms-link.active {
  color: var(--secondary-color)
}

.sidebar .menu-list .m-link:hover::before,
.sidebar .menu-list .m-link.active::before,
.sidebar .menu-list .ms-link:hover::before,
.sidebar .menu-list .ms-link.active::before {
  display: block
}

.sidebar .menu-list .m-link {
  color: var(--white-color);
  align-items: center;
  padding: 8px 0;
  font-size: 17px
}

.sidebar .menu-list .ms-link {
  color: var(--white-color);
  position: relative;
  padding: 5px 0
}

.sidebar .menu-list .ms-link span {
  font-size: 14px
}

.sidebar .menu-list .fa {
  width: 30px
}

.sidebar .menu-list .arrow {
  color: var(--white-color);
  opacity: 0.3;
  font-size: 10px
}

.sidebar .menu-list .divider {
  color: var(--white-color);
  border-color: rgba(255, 255, 255, 0.3) !important;
  opacity: 0.5
}

.sidebar .menu-list [class^="icofont-"] {
  width: 30px
}

.sidebar .menu-list .sub-menu {
  list-style: none;
  position: relative;
  padding-left: 30px
}

.sidebar .menu-list .sub-menu::before {
  background-color: var(--white-color);
  content: "";
  position: absolute;
  height: 100%;
  width: 0px;
  left: 8px;
  top: 0
}

.sidebar .menu-list .sub-menu .ms-link::before {
  top: 10px
}

.sidebar .sidebar-mini-btn {
  font-size: 34px
}

.sidebar .sidebar-mini-btn:focus,
.sidebar .sidebar-mini-btn:active {
  outline: none !important;
  box-shadow: none !important
}

.sidebar .sidebar-mini-btn .icofont-bubble-right:before {
  content: "\ea64"
}

@media only screen and (max-width: 767px) {
  .sidebar .sidebar-mini-btn {
    display: none
  }
}

.sidebar.gradient {
  background: var(--primary-gradient)
}

.sidebar.gradient.sidebar-mini .menu-list .sub-menu {
  background: var(--primary-gradient)
}

.sidebar.sidebar-mini {
  text-align: center;
  padding: 1.5rem 0 !important;
  width: 100px;
  z-index: 9999
}

@media only screen and (min-width: 1279px) {
  .sidebar.sidebar-mini {
    overflow: visible
  }
}

.sidebar.sidebar-mini .brand-icon {
  display: block
}

.sidebar.sidebar-mini .brand-icon .logo-icon {
  margin: 0 auto
}

.sidebar.sidebar-mini .brand-icon .logo-text {
  padding-left: 0px;
  padding-top: 8px
}

.sidebar.sidebar-mini .sidebar-title span,
.sidebar.sidebar-mini form {
  display: none
}

.sidebar.sidebar-mini .menu-list>li {
  position: relative
}

.sidebar.sidebar-mini .menu-list>li:hover .sub-menu {
  display: block !important
}

.sidebar.sidebar-mini .menu-list .m-link {
  margin-bottom: 10px
}

.sidebar.sidebar-mini .menu-list .m-link span {
  display: none
}

.sidebar.sidebar-mini .menu-list .m-link:hover span {
  display: block;
  background: var(--primary-color);
  border-radius: .25rem;
  box-shadow: 0 10px 40px 10px rgba(33, 37, 41, 0.2);
  width: 200px;
  padding: 10px 0px 10px 30px;
  text-align: left;
  position: absolute;
  left: 101px;
  top: 0;
  font-size: 17px
}

.sidebar.sidebar-mini .menu-list .m-link:hover span.badge {
  font-size: 13px
}

.sidebar.sidebar-mini .menu-list .m-link:hover span.badge:before {
  top: 12px
}

.sidebar.sidebar-mini .menu-list .ms-link::before {
  left: -16px
}

.sidebar.sidebar-mini .menu-list .ms-link span {
  font-size: 17px
}

.sidebar.sidebar-mini .menu-list .fa {
  width: 100%
}

.sidebar.sidebar-mini .menu-list .sub-menu {
  background: var(--primary-color);
  border-radius: .25rem;
  box-shadow: 0 10px 40px 10px rgba(33, 37, 41, 0.2);
  width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  left: 101px;
  top: 0;
  padding-left: 15px
}

.sidebar.sidebar-mini .menu-list .sub-menu:before {
  display: none
}

.sidebar.sidebar-mini .menu-list .sub-menu .ms-link::before {
  top: 12px
}

.sidebar.sidebar-mini .menu-list .divider small,
.sidebar.sidebar-mini .menu-list .divider .small {
  display: none
}

.sidebar.sidebar-mini .menu-list [class^="icofont-"] {
  width: 100%
}

.sidebar.sidebar-mini .fa-arrow-left::before {
  content: "\f061"
}

.sidebar.sidebar-mini #menu-Components {
  width: 300px
}

.sidebar.sidebar-mini #menu-Components li {
  width: 50%;
  float: left
}

.sidebar.sidebar-mini #menu-Authentication {
  width: 230px
}

.sidebar.sidebar-mini .sub-menu.show {
  display: none
}

.sidebar.sidebar-mini .sidebar-mini-btn .icofont-bubble-right:before {
  content: "\ea65"
}

.sidebar.sidebar-mini .form-switch label {
  display: none
}

.sidebar.sidebar-mini .form-switch.theme-rtl {
  padding-left: 2.5em
}

.sidebar.open {
  left: 0px
}

@media only screen and (max-width: 991px) {
  .sidebar.open {
    left: 0
  }
}

.sidebar .brand-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.sidebar .brand-icon .logo-icon {
  display: flex;
  align-items: center;
  justify-content: center
}

.sidebar .brand-icon .logo-icon i {
  color: #fff
}

.sidebar .brand-icon .logo-icon svg {
  width: 50px
}

.sidebar .brand-icon .logo-icon svg path {
  fill: var(--primary-color)
}

@media only screen and (max-width: 767px) {
  .sidebar .brand-icon .logo-icon svg {
    width: 25px
  }
}

.sidebar .brand-icon .logo-text {
  font-weight: bold;
  color: var(--white-color);
  font-size: 18px;
  display: inline-block;
  padding-left: 15px;
  padding-top: 9px
}

@media only screen and (max-width: 767px) {
  .sidebar .brand-icon .logo-text {
    font-size: 17px
  }
}

.menu-toggle {
  color: var(--text-color)
}

@media only screen and (min-width: 1280px) {
  .menu-toggle {
    display: none
  }
}

.main {
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100vh;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.main::-webkit-scrollbar {
  width: 4px
}

.main:hover::-webkit-scrollbar-thumb {
  visibility: visible
}

.main:hover::-webkit-scrollbar-track {
  visibility: visible
}

.main::-webkit-scrollbar-track {
  visibility: hidden;
  background: transparent
}

.main::-webkit-scrollbar-thumb {
  visibility: hidden;
  opacity: 0.2;
  background: var(--secondary-color);
  border-radius: 5px
}

.main::-webkit-scrollbar-thumb:hover {
  opacity: 1
}

.main .body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0
}

.notifications {
  z-index: 9999 !important
}

.notifications .pulse,
.notifications .pulse:focus {
  color: var(--text-color) !important
}

.notifications .card .card-header {
  background-color: var(--primary-color);
  color: #fff
}

.notifications .card .card-header .nav-tabs .nav-link {
  color: rgba(255, 255, 255, 0.2);
  font-size: 1rem;
  border: 0
}

.notifications .card .card-header .nav-tabs .nav-link.active,
.notifications .card .card-header .nav-tabs .nav-link:hover {
  color: var(--secondary-color);
  background-color: transparent
}

.notifications .card .card-header .nav-tabs .nav-link.active::after,
.notifications .card .card-header .nav-tabs .nav-link:hover::after {
  content: '';
  width: 7px;
  height: 7px;
  background-color: var(--secondary-color);
  display: block;
  border-radius: 50%;
  margin: 5px auto 0
}

.notifications .card .list {
  overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 330px;
  overflow-y: auto
}

.notifications .card .list::-webkit-scrollbar {
  width: 0
}

@media only screen and (max-width: 767px) {
  .notifications .dropdown-menu .w380 {
    width: 320px
  }
}

@media only screen and (max-width: 380px) {
  .notifications .dropdown-menu .w380 {
    width: 280px
  }
}

@media only screen and (max-width: 1279px) {
  .h-right {
    margin-left: auto;
    padding-right: 15px
  }
}

@media only screen and (max-width: 767px) {
  .h-right {
    margin-left: unset;
    padding-right: unset
  }
}

@media only screen and (max-width: 500px) {
  .h-right {
    width: 348px;
    justify-content: end
  }
}

@media only screen and (max-width: 400px) {
  .h-right {
    width: auto;
    margin-left: auto;
    padding-right: 12px
  }
}

@media only screen and (max-width: 500px) {
  .h-right .user-profile .u-info {
    display: none
  }
}

.h-right .user-profile .dropdown-menu .card.w280 {
  width: 240px
}

@media (max-width: 480px) {
  .h-right .notifications {
    display: none
  }
}

.h-right .nav-link {
  text-decoration: none;
  outline: none
}

.fc * {
  color: var(--primary-color)
}

.fc .fc-col-header-cell-cushion {
  color: var(--primary-color)
}

.fc .fc-h-event .fc-event-title {
  color: var(--white-color)
}

.fc .fc-h-event {
  background-color: var(--primary-color);
  border-color: var(--border-color)
}

.fc .fc-daygrid-event-dot {
  border-color: var(--secondary-color)
}

.fc .fc-button-primary {
  background-color: var(--secondary-color);
  color: var(--white-color);
  border-color: var(--secondary-color)
}

.fc .fc-button-primary .fc-icon {
  color: var(--white-color)
}

@media only screen and (max-width: 1280px) {
  .fc .fc-daygrid-event {
    font-size: 12px
  }
}

@media only screen and (max-width: 991px) {
  .fc .fc-daygrid-event {
    font-size: 8px
  }
}

@media only screen and (max-width: 768px) {
  .fc .fc-daygrid-event {
    display: none
  }
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: var(--border-color)
}

.card-chat,
.card-chat-body {
  height: 100vh
}

.chatlist-toggle {
  color: var(--primary-color)
}

.card-chat {
  min-width: 380px
}

@media only screen and (max-width: 991px) {
  .card-chat {
    -moz-transition: ease 0.1s;
    -o-transition: ease 0.1s;
    -webkit-transition: ease 0.1s;
    transition: ease 0.1s;
    position: fixed;
    left: -600px;
    z-index: 9
  }

  .card-chat.open {
    left: 0
  }
}

@media only screen and (max-width: 767px) {
  .card-chat.open {
    min-width: 300px;
    width: 300px
  }
}

@media only screen and (max-width: 360px) {
  .card-chat.open {
    min-width: 260px;
    width: 260px
  }
}

.card-chat .tab-content {
  overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
  height: calc(100vh - 150px)
}

.card-chat .tab-content::-webkit-scrollbar {
  width: 0
}

.card-chat .list-group .list-group-item.open,
.card-chat .list-group .list-group-item:hover {
  background-color: var(--body-color)
}

.card-chat .list-group .list-group-item a {
  color: var(--color-500)
}

.card-chat .list-group .msg-time {
  font-size: 11px;
  font-weight: 600
}

@media only screen and (max-width: 767px) {
  .card-chat .nav-link {
    padding: 5px
  }
}

@media only screen and (max-width: 767px) {
  .card-chat-body {
    height: calc(100vh - 60px)
  }
}

.card-chat-body .max-width-70 {
  max-width: 70%
}

@media only screen and (max-width: 768px) {
  .card-chat-body .max-width-70 {
    max-width: 100%
  }
}

.card-chat-body .chat-history {
  overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100vh;
  overflow-y: auto
}

.card-chat-body .chat-history::-webkit-scrollbar {
  width: 0
}

.card-chat-body .text-right .message {
  color: #fff
}

.card-chat-body .message {
  color: var(--color-600)
}

.card-chat-body.card {
  background-color: var(--body-color)
}

.timeline-item {
  border-left: 2px solid var(--border-color);
  display: flex;
  position: relative;
  padding: 1em 2em 2em
}

.timeline-item:hover::after {
  transform: scale(3.5)
}

.timeline-item::after {
  -moz-transition: ease 0.1s;
  -o-transition: ease 0.1s;
  -webkit-transition: ease 0.1s;
  transition: ease 0.1s;
  border: 2px solid var(--border-color);
  background: var(--color-900);
  display: block;
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  top: 1.3em;
  left: -5px;
  border-radius: 30px
}

.timeline-item.ti-primary:after {
  background: var(--primary-color)
}

.timeline-item.ti-info:after {
  background: #4FB8C9
}

.timeline-item.ti-success:after {
  background: #7EB93F
}

.timeline-item.ti-warning:after {
  background: #f8b500
}

.timeline-item.ti-danger:after {
  background: #FF1843
}

.product-cart .checkout-table .table {
  margin-bottom: 0
}

.product-cart .checkout-table .table thead tr th {
  padding: 9px 15px;
  border: 0;
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  font-weight: bold
}

@media (max-width: 991px) {

  .product-cart .checkout-table .table thead tr th:before,
  .product-cart .checkout-table .table thead tr th:after {
    display: none
  }
}

.product-cart .checkout-table .table thead tr th.product {
  min-width: 240px
}

@media (max-width: 991px) {
  .product-cart .checkout-table .table thead tr th.product {
    min-width: 100%
  }
}

.product-cart .checkout-table .table thead tr th.size {
  min-width: 80px;
  width: 80px
}

.product-cart .checkout-table .table thead tr th.quantity {
  width: 100px
}

.product-cart .checkout-table .table thead tr th.price {
  min-width: 80px;
  width: 80px
}

.product-cart .checkout-table .table thead tr th.action {
  min-width: 100px;
  width: 100px
}

.product-cart .checkout-table .table tbody tr td {
  border: 0;
  border-bottom: 1px solid var(--border-color);
  padding: 15px;
  vertical-align: middle
}

.product-cart .checkout-table .product-cart .product-content {
  padding-left: 8px
}

.product-cart .checkout-table .product-cart .product-content .title a {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  transition: all 0.3s linear
}

.product-cart .checkout-table .product-cart .product-content span {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  margin-top: 4px
}

.product-cart .checkout-table .product-size {
  position: relative
}

.product-cart .checkout-table .product-size .nice-select {
  width: 70px;
  border: 1px solid var(--border-color);
  height: 35px;
  line-height: 33px;
  background-color: transparent;
  padding: 0 10px;
  float: none;
  border-radius: 4px
}

.product-cart .checkout-table .product-size .nice-select::after {
  width: 9px;
  height: 9px;
  right: 10px;
  border-color: #000
}

.product-cart .checkout-table .product-size .nice-select .current {
  font-size: 16px;
  color: #000;
  line-height: 33px;
  font-weight: 500
}

.product-cart .checkout-table .product-size .nice-select .list {
  width: 100%
}

.product-cart .checkout-table .product-quantity {
  border: 1px solid var(--border-color);
  overflow: hidden;
  border-radius: 4px;
  display: inline-block
}

.product-cart .checkout-table .product-quantity input {
  width: 100px;
  height: 35px;
  border: 0;
  padding: 0 5px;
  text-align: center;
  font-weight: 700;
  background-color: transparent;
  color: var(--text-color)
}

@media (max-width: 767px) {
  .product-cart .checkout-table .product-quantity input {
    width: 80px
  }
}

.product-cart .checkout-table .price {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0px
}

.product-cart .checkout-table .action {
  padding: 0px;
  margin-bottom: 0px
}

.product-cart .checkout-table .action li {
  display: inline-block
}

.product-cart .checkout-table .action li a {
  font-size: 24px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center
}

.product-cart .checkout-table .action li a.favorite {
  color: #7D7D7D
}

.product-cart .checkout-table .action li a.delete {
  color: #FF4B22
}

.product-cart .checkout-coupon-total .checkout-coupon {
  width: 50%;
  padding: 15px 0px
}

.product-cart .checkout-coupon-total .checkout-coupon span {
  font-size: 13px;
  line-height: 16px
}

.product-cart .checkout-coupon-total .checkout-total {
  width: 50%;
  border-left: 1px solid var(--border-color)
}

.product-cart .checkout-coupon-total .checkout-total .single-total {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  padding: 0 8px
}

.product-cart .checkout-coupon-total .checkout-total .single-total .value {
  font-weight: 300;
  font-size: 14px;
  line-height: 32px;
  margin-right: 16px
}

.product-cart .checkout-coupon-total .checkout-total .single-total .price {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  width: 85px
}

.product-cart .checkout-coupon-total .checkout-total .single-total.total-payable {
  border-top: 1px solid var(--border-color)
}

.product-cart .checkout-coupon-total .checkout-total .single-total.total-payable .value {
  font-weight: 500
}

.product-cart .checkout-coupon-total.checkout-coupon-total-2 .checkout-coupon {
  width: 70%
}

.product-cart .checkout-coupon-total.checkout-coupon-total-2 .checkout-total {
  width: 30%
}

.product-cart .checkout-coupon-total.checkout-coupon-total-2 .checkout-total .single-total {
  justify-content: space-between
}

.product-cart .checkout-coupon-total.checkout-coupon-total-2 .checkout-total .single-total .price {
  width: auto
}

.product-cart .checkout-btn {
  padding: 0px 15px 15px;
  border-top: 1px solid var(--border-color)
}

.product-cart .checkout-btn .single-btn {
  margin-bottom: 5px
}

@media (max-width: 767px) {
  .product-cart .checkout-coupon-total .checkout-coupon {
    width: 100%;
    padding: 15px 0px
  }

  .product-cart .checkout-coupon-total .checkout-total {
    width: 100%;
    border-left: 0;
    border-top: 1px solid var(--border-color)
  }

  .product-cart .checkout-coupon-total.checkout-coupon-total-2 .checkout-coupon {
    width: 100%;
    padding: 15px 0px
  }

  .product-cart .checkout-coupon-total.checkout-coupon-total-2 .checkout-total {
    width: 100%;
    padding: 15px 0px
  }

  .product-cart .checkout-btn {
    padding: 15px 0px
  }
}

.checkout-steps ul {
  list-style: none;
  padding: 0px
}

.checkout-steps ul li {
  border-radius: 8px
}

.checkout-steps ul li+li {
  margin-top: 12px
}

.checkout-steps .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  position: relative;
  cursor: pointer
}

.checkout-steps .title::before {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  content: "\ea99";
  font-family: 'IcoFont' !important;
  font-size: 24px;
  color: var(--primary-color);
  transition: all 0.3s linear
}

.checkout-steps .checkout-steps-form-content {
  padding-top: 0;
  border-top: 1px solid var(--border-color)
}

.checkout-steps .checkout-steps-form-content .steps-form-btn {
  padding-top: 10px
}

.checkout-steps .checkout-steps-form-content .checkout-payment-option {
  margin-top: 16px
}

.checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px
}

.checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option {
  width: 25%;
  margin-top: 16px;
  padding: 0 4px
}

.checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option input[type="radio"] {
  display: none
}

.checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option input[type="radio"]+label {
  border: 1px solid var(--border-color)
}

.checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option input[type="radio"]:checked+label {
  border: 1px solid var(--primary-color)
}

.checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option label {
  text-align: center;
  padding: 12px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin-bottom: 0;
  width: 100%;
  transition: all 0.3s linear;
  cursor: pointer
}

.checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option label .s-info {
  font-size: 14px;
  font-weight: 300;
  margin-top: 12px;
  display: block;
  margin-bottom: 15px
}

.checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option label .price {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-top: 4px
}

@media (max-width: 768px) {
  .checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option {
    width: 50%
  }

  .checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option label {
    padding: 8px
  }

  .checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option label p {
    font-size: 12px
  }

  .checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option label .price {
    font-size: 14px
  }
}

@media (max-width: 480px) {
  .checkout-steps .checkout-steps-form-content .checkout-payment-option .payment-option-wrapper .single-payment-option {
    width: 100%
  }

  .fc .fc-toolbar-title {
    font-size: 18px
  }
}

.checkout-sidebar-price-table .title {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  padding-bottom: 12px
}

.checkout-sidebar-price-table .sub-total-price {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  padding: 8px 0
}

.checkout-sidebar-price-table .sub-total-price .total-price {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between
}

.checkout-sidebar-price-table .sub-total-price .total-price .value {
  line-height: 32px;
  font-size: 14px
}

.checkout-sidebar-price-table .sub-total-price .total-price .price {
  line-height: 32px;
  font-size: 16px
}

.checkout-sidebar-price-table .sub-total-price .shipping .value {
  color: #FF4B22
}

.checkout-sidebar-price-table .sub-total-price .shipping .price {
  color: #FF4B22
}

.checkout-sidebar-price-table .sub-total-price .discount .value {
  color: #7CB637
}

.checkout-sidebar-price-table .sub-total-price .discount .price {
  color: #7CB637
}

.checkout-sidebar-price-table .total-payable {
  padding: 8px 0
}

.checkout-sidebar-price-table .total-payable .payable-price {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between
}

.checkout-sidebar-price-table .total-payable .payable-price .value {
  line-height: 32px;
  font-size: 14px;
  font-weight: 500
}

.checkout-sidebar-price-table .total-payable .payable-price .price {
  line-height: 32px;
  font-size: 16px;
  font-weight: 500
}

.checkout-sidebar-price-table .price-table-btn {
  margin-top: 16px
}

.product-details .product-details-content .product-items {
  margin-top: 32px
}

.product-details .product-details-content .product-items .item-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px
}

.product-details .product-details-content .product-items .items-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex
}

.product-details .product-details-content .product-items .items-wrapper .single-item {
  max-width: 130px;
  text-align: center;
  -moz-flex: 1;
  flex: 1;
  margin-top: 8px
}

.product-details .product-details-content .product-items .items-wrapper .single-item+.single-item {
  margin-left: 8px
}

.product-details .product-details-content .product-items .items-wrapper .single-item .items-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.3s linear
}

.product-details .product-details-content .product-items .items-wrapper .single-item .text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-top: 11px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details .product-details-content .product-items .items-wrapper .single-item {
    max-width: 110px
  }
}

.product-details .product-details-content .product-items .items-wrapper .single-item.active .items-image img {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.32);
  border-color: var(--secondary-color)
}

.product-details .product-details-content .product-select-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px
}

.product-details .product-details-content .product-select-wrapper .select-title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400
}

.product-details .product-details-content .product-select-wrapper .select-title span {
  font-weight: 500;
  margin-left: 5px
}

.product-details .product-details-content .product-select-wrapper .select-item {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10x
}

.product-details .product-details-content .product-select-wrapper .select-item .color-select {
  padding-top: 8px;
  padding-left: 0px;
  margin-bottom: 0px
}

.product-details .product-details-content .product-select-wrapper .select-item .color-select li {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.3s linear
}

.product-details .product-details-content .product-select-wrapper .select-item .color-select li+li {
  margin-left: 8px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details .product-details-content .product-select-wrapper .select-item .color-select li {
    width: 30px;
    height: 30px
  }
}

.product-details .product-details-content .product-select-wrapper .select-item .color-select li.active {
  border-color: var(--secondary-color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.32)
}

.product-details .product-details-content .product-price {
  padding-top: 24px
}

.product-details .product-details-content .product-price .price-title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400
}

.product-details .product-details-content .product-price .sale-price {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  margin-top: 8px;
  display: inline-block;
  margin-right: 16px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details .product-details-content .product-price .sale-price {
    font-size: 30px;
    line-height: 40px
  }
}

.product-details .product-details-content .product-price .regular-price {
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  color: var(--color-400);
  margin-top: 8px;
  text-decoration: line-through;
  text-decoration-thickness: 3px;
  display: inline-block
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details .product-details-content .product-price .regular-price {
    font-size: 24px;
    line-height: 30px
  }
}

.product-details .product-details-content .product-btn {
  padding-top: 24px
}

.product-details .product-details-image {
  position: relative;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center
}

.product-details .product-details-image .product-image {
  width: 85%
}

.product-details .product-details-image .product-image .single-image img {
  width: 100%
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details .product-details-image .product-image {
    width: 83%
  }
}

.product-details .product-details-image .product-thumb-image {
  width: 15%;
  text-align: center
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details .product-details-image .product-thumb-image {
    width: 17%
  }
}

.product-details .product-details-image .product-thumb-image .single-thumb {
  cursor: pointer;
  border-radius: 4px;
  margin: 5px 10px
}

.product-details .product-details-image .product-thumb-image .single-thumb img {
  width: 60px;
  border-radius: 4px;
  opacity: 0.74;
  margin: auto;
  transition: all 0.3s linear
}

.product-details .product-details-image .product-thumb-image .single-thumb.active img {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.32);
  opacity: 1
}

@media (max-width: 767px) {
  .product-details .product-details-content .product-items .item-title {
    font-size: 14px
  }

  .product-details .product-details-content .product-select-wrapper .select-title {
    font-size: 14px
  }

  .product-details .product-details-content .product-select-wrapper .select-item .color-select li {
    width: 25px;
    height: 25px
  }

  .product-details .product-details-content .product-price .price-title {
    font-size: 14px
  }

  .product-details .product-details-content .product-price .sale-price {
    font-size: 28px;
    line-height: 38px
  }

  .product-details .product-details-content .product-price .regular-price {
    font-size: 20px;
    line-height: 34px
  }

  .product-details .product-details-image .product-image {
    width: 83%
  }

  .product-details .product-details-image .product-thumb-image {
    width: 17%
  }

  .product-details .product-details-image .product-thumb-image .single-thumb {
    margin: 3px
  }

  .product-details .product-details-image .product-thumb-image .single-thumb img {
    width: 40px
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details .product-details-content .product-select-wrapper .select-title {
    font-size: 16px
  }

  .product-details .product-details-content .product-select-wrapper .select-item .color-select li {
    width: 30px;
    height: 30px
  }

  .product-details .product-details-content .product-select-wrapper .select-item .select-quantity button {
    height: 34px;
    width: 34px
  }

  .product-details .product-details-content .product-select-wrapper .select-item .select-quantity input {
    height: 35px;
    width: 45px;
    font-size: 16px
  }

  .product-details .product-details-image .product-image {
    width: 85%
  }

  .product-details .product-details-image .product-thumb-image {
    width: 15%
  }

  .product-details .product-details-image .product-thumb-image .single-thumb img {
    width: 60px
  }
}

.product {
  position: relative;
  padding: 10px
}

.product .add-wishlist {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 24px;
  color: #E14C7B;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
  transition: all 0.3s linear
}

.product .add-wishlist:hover i::before {
  content: "\f3f5"
}

.group-link {
  border: 1px solid var(--primary-color);
  border-radius: 3px;
  padding: 3px
}

.group-link .active {
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 3px 3px 3px 3px !important
}

.group-link .active:hover {
  color: var(--white-color)
}

.size-block,
.price-range-block,
.rating-block,
.color-block,
.categories {
  padding: 15px
}

.size-block .filter-title .title,
.price-range-block .filter-title .title,
.rating-block .filter-title .title,
.color-block .filter-title .title,
.categories .filter-title .title {
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  display: block;
  position: relative
}

.size-block .filter-title .title::before,
.price-range-block .filter-title .title::before,
.rating-block .filter-title .title::before,
.color-block .filter-title .title::before,
.categories .filter-title .title::before {
  position: absolute;
  content: "\ea99";
  font-family: 'IcoFont' !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 24px;
  color: var(--primary-color)
}

.size-block .filter-title .title.collapsed::before,
.price-range-block .filter-title .title.collapsed::before,
.rating-block .filter-title .title.collapsed::before,
.color-block .filter-title .title.collapsed::before,
.categories .filter-title .title.collapsed::before {
  content: "\eaa1"
}

.size-block ul,
.price-range-block ul,
.rating-block ul,
.color-block ul,
.categories ul {
  list-style: none;
  padding-left: 0px
}

.reset-block {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.reset-block .filter-title .title {
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  display: block;
  position: relative;
  margin-bottom: 0px
}

.size-block .filter-size {
  padding-top: 8px
}

.size-block .filter-size ul li {
  display: inline-block;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 33px;
  border: 1px solid var(--border-color);
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  margin-top: 8px;
  transition: all 0.3s linear;
  cursor: pointer
}

.size-block .filter-size ul li+li {
  margin-left: 2px
}

.size-block .filter-size ul li:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff
}

.size-block .filter-size ul li.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff
}

.price-range-block .price-range .price-amount {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between
}

.price-range-block .price-range .price-amount .amount-input {
  width: 50%
}

.price-range-block .price-range .price-amount .amount-input label {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  display: block
}

.price-range-block .price-range .price-amount .amount-input input {
  display: inline-block;
  width: 95%;
  height: 40px;
  border: 1px solid var(--border-color);
  padding: 0 16px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px
}

.price-range-block .price-range .price-amount .amount-input:last-child label {
  padding-left: 0
}

.price-range-block .price-range .slider-range {
  height: 4px;
  width: 100%;
  border: 0;
  border-radius: 10px;
  background-color: var(--border-color);
  margin-top: 38px;
  margin-bottom: 13px
}

.price-range-block .price-range .slider-range .ui-slider-range {
  background-color: var(--primary-color)
}

.price-range-block .price-range .slider-range .ui-slider-handle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--primary-color);
  top: -13px;
  cursor: pointer;
  border: 0
}

.price-range-block .price-range .slider-range .ui-slider-handle:focus {
  outline: 0
}

.price-range-block .noUi-base {
  width: 97%;
  left: 0;
  right: 0;
  margin: auto
}

.price-range-block .noUi-connect {
  background-color: var(--primary-color)
}

.price-range-block .noUi-handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
  top: -9px;
  cursor: pointer;
  border: 0;
  box-shadow: none
}

.price-range-block .noUi-handle:after {
  display: none
}

.price-range-block .noUi-handle:before {
  display: none
}

.rating-block .filter-rating {
  padding-top: 8px
}

.rating-block .filter-rating .rating-check {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-top: 8px
}

.rating-block .filter-rating .rating-check input[type="checkbox"] {
  display: none
}

.rating-block .filter-rating .rating-check input[type="checkbox"]+label span {
  width: 17px;
  height: 17px;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin: 0 5px -3px 0;
  transition: all 0.4s linear
}

.rating-block .filter-rating .rating-check input[type="checkbox"]+label span::before {
  position: absolute;
  content: '\eed8';
  font-family: 'IcoFont' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 1px;
  left: -1px;
  width: 100%;
  height: 100%;
  transition: all 0.4s linear;
  line-height: 0.8;
  font-size: 16px;
  color: #fff;
  opacity: 0;
  visibility: hidden
}

.rating-block .filter-rating .rating-check input[type="checkbox"]:checked+label span {
  background-color: var(--primary-color);
  border-color: var(--primary-color)
}

.rating-block .filter-rating .rating-check input[type="checkbox"]:checked+label span::before {
  opacity: 1;
  visibility: visible
}

.rating-block .filter-rating .rating-check p {
  padding-left: 5px
}

.rating-block .filter-rating .rating-check p i {
  color: #FFBE27;
  font-size: 20px
}

.color-block .filter-color {
  padding-top: 8px
}

.color-block .filter-color .color-check {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  margin-top: 8px
}

.color-block .filter-color .color-check p {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex
}

.color-block .filter-color .color-check p span {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 11px
}

.color-block .filter-color .color-check p strong {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px
}

.color-block .filter-color .color-check input[type="checkbox"] {
  display: none
}

.color-block .filter-color .color-check input[type="checkbox"]+label span {
  width: 17px;
  height: 17px;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin: 0 5px -3px 0;
  transition: all 0.4s linear
}

.color-block .filter-color .color-check input[type="checkbox"]+label span::before {
  position: absolute;
  content: '\eed8';
  font-family: 'IcoFont' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 1px;
  left: -1px;
  width: 100%;
  height: 100%;
  transition: all 0.4s linear;
  line-height: 0.8;
  font-size: 16px;
  color: #fff;
  opacity: 0;
  visibility: hidden
}

.color-block .filter-color .color-check input[type="checkbox"]:checked+label span {
  background-color: var(--primary-color);
  border-color: var(--primary-color)
}

.color-block .filter-color .color-check input[type="checkbox"]:checked+label span::before {
  opacity: 1;
  visibility: visible
}

.categories .filter-search {
  margin-top: 16px;
  position: relative
}

.categories .filter-search input {
  width: 100%;
  height: 40px;
  border: 1px solid var(--border-color);
  padding: 0 16px;
  border-radius: 4px
}

.categories .filter-search button {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: 0;
  background: none;
  color: var(--primary-color)
}

.categories .filter-category .category-list {
  padding-top: 8px
}

.categories .filter-category .category-list li {
  margin-top: 8px
}

.categories .filter-category .category-list li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  display: block
}

.categories .filter-category .category-list li a::before {
  position: absolute;
  content: "\ea99";
  font-family: 'IcoFont' !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 24px;
  color: var(--primary-color)
}

.categories .filter-category .category-list li .sub-category {
  padding: 8px 0;
  padding-left: 34px
}

.categories .filter-category .category-list li .sub-category li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  display: block
}

.categories .filter-category .category-list li .sub-category li a::before {
  display: none
}

.ck.ck-toolbar {
  background: var(--card-color) !important;
  border: 1px solid var(--border-color) !important
}

.ck.ck-reset_all,
.ck.ck-reset_all * {
  color: var(--text-color) !important
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--border-color) !important
}

.ck.ck-editor__main>.ck-editor__editable {
  background: var(--card-color) !important
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: var(--color-100)
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
  top: 50%;
  background-color: var(--primary-color);
  transform: translateY(-50%)
}

table.dataTable>tbody>tr.child ul.dtr-details {
  display: block
}

@media only screen and (max-width: 767px) {

  #myProjectTable_length,
  #myProjectTable_filter,
  #myProjectTable_info,
  #myDataTable_length,
  #myDataTable_filter,
  #myDataTable_info,
  #patient-table_length,
  #patient-table_filter,
  #patient-table_info {
    display: none
  }
}

@media only screen and (max-width: 767px) {

  #myProjectTable_paginate ul.pagination,
  #myDataTable_paginate ul.pagination,
  #patient-table_paginate ul.pagination {
    justify-content: start !important
  }
}

#myCartTable_length,
#myCartTable_filter,
#myCartTable_info,
#myCartTable_paginate {
  display: none
}

#apex-circle-gradient circle {
  fill: var(--card-color)
}

.ac-line-transparent line {
  stroke: var(--card-color)
}

.apexcharts-canvas line {
  stroke: var(--border-color)
}

.apexcharts-canvas text {
  fill: var(--color-500)
}

.apexcharts-canvas circle {
  stroke: var(--border-color)
}

.apexcharts-canvas .apexcharts-legend-text {
  color: var(--color-500) !important
}

.sparkline_chart canvas {
  width: 100% !important
}

.line-color .apexcharts-canvas line {
  stroke: var(--card-color) !important
}

#apex-emplyoeeAnalytics .apexcharts-canvas line {
  stroke: transparent !important
}

#apex-MainCategories .apexcharts-canvas,
#incomeanalytics .apexcharts-canvas {
  margin: 0 auto
}

.todo-list li {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  padding: 14px 0;
  font-size: 1rem
}

.todo-list li:last-child {
  border-bottom: 0
}

.todo-list li .fa-check {
  color: var(--color-400)
}

.todo-list li.active>span {
  color: var(--primary-color);
  text-decoration: line-through
}

.todo-list li.active .fa-check {
  color: var(--primary-color)
}

.card {
  border-color: var(--border-color);
  background-color: var(--card-color)
}

.card.card-callout {
  border-left-width: .4rem;
  border-left-color: var(--primary-color)
}

.card .card-header {
  background-color: transparent;
  border-bottom: none
}

.card .card-footer {
  border-color: var(--border-color)
}

.row-deck>.col,
.row-deck>[class*='col-'] {
  display: flex;
  align-items: stretch
}

.row-deck>.col .card,
.row-deck>[class*='col-'] .card {
  flex: 1 1 auto
}

:fullscreen, ::backdrop {
  background-color: #ffffff;
}

